import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const addSuitCartItem = createAsyncThunk('appEcommerce/addSuitCartItem', async (data) => {
  const existingCartItems = JSON.parse(localStorage.getItem('cartItem')) || [];
  const updatedCart = [...existingCartItems, data];
  localStorage.setItem('cartItem', JSON.stringify(updatedCart));
  return updatedCart;
});

export const removeCartItems = createAsyncThunk('appEcommerce/removeCartItems', async (productId) => {
  const existingCartItems = JSON.parse(localStorage.getItem('cartItem')) || [];
  const updatedCart = existingCartItems.filter(item => item.id !== productId);
  localStorage.setItem('cartItem', JSON.stringify(updatedCart));
  return updatedCart;
});

export const appEcommerceSlice = createSlice({
  name: 'appEcommerce',
  initialState: {
    cart: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addSuitCartItem.fulfilled, (state, action) => {
        state.cart = action.payload;
      }).addCase(removeCartItems.fulfilled, (state, action) => {
        state.cart = action.payload;
      });
  },
});

export default appEcommerceSlice.reducer;

